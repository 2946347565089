import React, { useEffect, useState } from 'react';
import './App.css';

function App() {
  const [loaded, setLoaded] = useState(false);
  const [charIndex, setCharIndex] = useState(0);
  const titleText = "RMW Consultants";
  
  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 1000);
    
    // Typing effect for the title
    if (charIndex < titleText.length) {
      const typingTimer = setTimeout(() => {
        setCharIndex(charIndex + 1);
      }, 120);
      return () => clearTimeout(typingTimer);
    }
  }, [charIndex]);

  return (
    <div className="App">
      <div className={`loading-overlay ${loaded ? 'fade-out' : ''}`}>
        <div className="loading-content">
          <div className="loading-logo">RMW</div>
          <div className="loading-bar"></div>
          <div className="loading-text">Establishing Secure Connection</div>
        </div>
      </div>
      
      <div className="digital-noise"></div>
      <div className="grid-overlay"></div>
      
      <header className="site-header">
        <div className="logo">RMW</div>
        <div className="header-line"></div>
        <div className="header-text">CONSULTANTS</div>
      </header>
      
      <main className="main-content">
        <section className="hero-section">
          <div className="title-container">
            <h1 className="title">
              <span className="title-line">
                <span className="title-typed">{titleText.substring(0, charIndex)}</span>
                <span className="title-cursor"></span>
              </span>
              <div className="title-effects">
                <div className="title-glow"></div>
                <div className="title-scan-line"></div>
              </div>
              <span className="subtitle">Secure Systems. Advanced Solutions.</span>
            </h1>
          </div>
          <div className="hero-graphic">
            <div className="tech-circle"></div>
            <div className="tech-lines"></div>
          </div>
        </section>
        
        <section className="about-section">
          <div className="section-content">
            <h2>Digital Security</h2>
            <p>
              Operating at the intersection of innovation and protection.
              Our network secures critical digital assets while deploying
              proprietary technologies that redefine security standards.
            </p>
            <div className="tech-indicator">
              <span className="pulse"></span>
              <span className="tech-label">Secure Protocol Active</span>
            </div>
          </div>
        </section>
        
        <section className="capabilities-section">
          <div className="capability-item">
            <div className="capability-icon tech-icon-1"></div>
            <h3>Secure Infrastructure</h3>
            <p>Advanced protection for critical digital systems</p>
          </div>
          <div className="capability-item">
            <div className="capability-icon tech-icon-2"></div>
            <h3>Commercial Assets</h3>
            <p>Strategic properties and retail operations</p>
          </div>
          <div className="capability-item">
            <div className="capability-icon tech-icon-3"></div>
            <h3>Encrypted Networks</h3>
            <p>Next-generation security protocols and solutions</p>
          </div>
        </section>
        
        <section className="tech-showcase">
          <div className="tech-visual">
            <div className="data-stream"></div>
            <div className="static-text">RMW</div>
          </div>
          <div className="tech-specs">
            <div className="spec-item">
              <div className="spec-label">Threat Detection</div>
              <div className="spec-bar"><span style={{width: '94%'}}></span></div>
            </div>
            <div className="spec-item">
              <div className="spec-label">Encryption Protocols</div>
              <div className="spec-bar"><span style={{width: '92%'}}></span></div>
            </div>
            <div className="spec-item">
              <div className="spec-label">Secure Architecture</div>
              <div className="spec-bar"><span style={{width: '89%'}}></span></div>
            </div>
          </div>
        </section>
        
        <section className="statement-section">
          <blockquote>
            "Security isn't just a feature. It's the foundation."
          </blockquote>
        </section>
      </main>
      
      <footer className="site-footer">
        <div className="footer-content">
          <div className="footer-logo">RMW</div>
          <div className="contact">
            <a href="mailto:inquiries@rmwconsultants.com">inquiries@rmwconsultants.com</a>
          </div>
          <div className="footer-text">
            © 2025 RMW Consultants.
          </div>
        </div>
      </footer>
    </div>
  );
}

export default App;
